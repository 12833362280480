
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiCheck, mdiHelpCircleOutline, mdiMinus } from "@mdi/js";

@Component({})
export default class WithdrawFee extends Vue {
  @Prop()
  commonNetworks?: string[];

  @Prop()
  withdrawFeeTicker?: number;

  @Prop()
  withdrawFeeBestArbitrage?: number;

  @Prop()
  isTransactionFeeLazy?: boolean;

  icons = {
    mdiCheck: mdiCheck,
    mdiMinus: mdiMinus,
    mdiHelpCircleOutline: mdiHelpCircleOutline,
  };
}
