
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiCheck, mdiMinus } from "@mdi/js";

@Component({})
export default class MarginInfo extends Vue {
  @Prop()
  margin?: boolean;

  @Prop()
  borrowLimit?: number;

  @Prop()
  currency?: string;

  icons = {
    mdiCheck: mdiCheck,
    mdiMinus: mdiMinus,
  };
}
