
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiCheck, mdiHelpCircleOutline } from "@mdi/js";

@Component({})
export default class CurrencyNameInfo extends Vue {
  @Prop()
  hasSameCurrencyName?: boolean;

  icons = {
    mdiCheck: mdiCheck,
    mdiHelpCircleOutline: mdiHelpCircleOutline,
  };
}
