export default class MathUtils {
  static round(number: number, precision = 0) {
    const multiplier = 10 ** precision;
    return Math.round(number * multiplier) / multiplier;
  }

  static trunc(number: number, precision = 0) {
    const multiplier = 10 ** precision;
    return Math.trunc(number * multiplier) / multiplier;
  }
}
