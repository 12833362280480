
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MarketOhlcvStatDTO } from "next-trader-api";
import { chunk, orderBy } from "lodash-es";
import { mdiInformation } from "@mdi/js";

@Component
export default class Transactions extends Vue {
  @Prop()
  marketOhlcvStatDTOs?: Array<MarketOhlcvStatDTO>;

  icons = {
    mdiInformation: mdiInformation,
  };

  getSortedData() {
    const ordered = orderBy(this.marketOhlcvStatDTOs, ["progressValueSum"], ["desc"]);
    return chunk(ordered, 10).entries();
  }
}
