
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiCheck, mdiHelpCircleOutline, mdiMinus } from "@mdi/js";
import { Network } from "ccxt";

@Component({})
export default class NetworkInfo extends Vue {
  @Prop()
  commonNetworks?: string[];

  @Prop()
  buyNetworks?: Network[];

  @Prop()
  sellNetworks?: Network[];

  @Prop()
  usedNetwork?: string;

  @Prop()
  buyExchange?: string;

  @Prop()
  sellExchange?: string;

  icons = {
    mdiCheck: mdiCheck,
    mdiMinus: mdiMinus,
    mdiHelpCircleOutline: mdiHelpCircleOutline,
  };

  getByExchange() {
    return this.buyExchange === "kucoin" || this.sellExchange === "kucoin" ? "green--text" : "orange--text";
  }
}
