
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiOpenInNew } from "@mdi/js";
import { Network } from "ccxt";

@Component({})
export default class ArbitrageSide extends Vue {
  @Prop()
  marketUrl?: string;

  @Prop()
  withdrawUrl?: string;

  @Prop()
  depositUrl?: string;

  @Prop()
  exchange?: string;

  @Prop()
  symbol?: string;

  @Prop()
  networks?: Array<Network>;

  @Prop()
  commonNetworks?: Array<string>;

  @Prop()
  margin?: boolean;

  @Prop()
  baseCurrencyName?: string;

  @Prop()
  sameExchange?: boolean;

  @Prop()
  side?: string;

  icons = {
    mdiOpenInNew: mdiOpenInNew,
  };

  get otherNetworks() {
    if (this.networks === undefined) {
      return [];
    }
    const otherNetworks = this.networks.filter((network) => !this.commonNetworks?.includes(network.network));

    // const networks = otherNetworks.map((network) => network.network);
    // const uniqueNetworks = [...new Set(networks)];
    // if (networks.length !== uniqueNetworks.length) {
    //   console.log(this.symbol, this.exchange, this.networks);
    // }

    return otherNetworks;
  }
}
