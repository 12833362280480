
import Vue from "vue";
import Component from "vue-class-component";
import OhlcvStat from "@/components/OhlcvStat.vue";
import MathUtils from "@/service/arbitrage-checker/MathUtils";
import dayjs from "dayjs";
import { ExchangeInfo } from "@/service/arbitrage-checker/ArbitrageTypes";
import { Prop } from "vue-property-decorator";
import { mdiAlertCircle, mdiAlertCircleOutline, mdiApi } from "@mdi/js";
import { ArbitrageHandler } from "@/service/arbitrage-checker/ArbitrageHandler";

@Component({
  components: { OhlcvStat },
})
export default class ExchangeStatus extends Vue {
  @Prop()
  forTickers?: boolean;

  @Prop()
  arbitrageHandler?: ArbitrageHandler;

  exchangeInfos: Array<ExchangeInfo> = [];

  timer?: number;
  currentTime = Date.now();

  dialogs = {};
  dialogs2 = {};

  icons = {
    mdiAlertCircleOutline: mdiAlertCircleOutline,
    mdiAlertCircle: mdiAlertCircle,
    mdiApi: mdiApi,
  };

  get exchangeInfosSorted() {
    return this.exchangeInfos.sort((a, b) => {
      return a.exchange > b.exchange ? 1 : b.exchange > a.exchange ? -1 : 0;
    });
  }

  takerFeePercent(takerFee?: number) {
    return takerFee !== undefined ? MathUtils.round(takerFee * 100, 4) + "%" : "-";
  }

  created() {
    if (!this.arbitrageHandler) {
      return;
    }

    const observable = this.forTickers
      ? this.arbitrageHandler.exchangeInfosAllTickers$
      : this.arbitrageHandler.exchangeInfosAllOrderBook$;

    observable.subscribe((exchangeInfos) => {
      //TODO Temporarily commented out, used too many resources, frozen the ui
      //this.mergeRequestInfos(exchangeInfos, this.exchangeInfos);
      this.exchangeInfos = exchangeInfos;
    });

    this.timer = setInterval(() => {
      this.currentTime = Date.now();
    }, 1000);
  }

  private mergeRequestInfos(exchangeInfosNew: ExchangeInfo[], exchangeInfosOld: ExchangeInfo[]) {
    for (const exchangeInfoNew of exchangeInfosNew) {
      const requestInfosOld =
        exchangeInfosOld.find((item) => item.exchange === exchangeInfoNew.exchange)?.requestInfos ?? [];
      requestInfosOld.push(...exchangeInfoNew.requestInfos);
      exchangeInfoNew.requestInfos = requestInfosOld;
    }
  }

  destroyed() {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
    }
  }

  dateFormat(date?: number) {
    if (date === undefined) {
      return "-";
    }

    const diffMs = this.currentTime - date;
    if (diffMs < 3000) {
      return "live";
    }

    const diffSec = dayjs().diff(date, "seconds");
    return diffSec + " second(s) ago";
  }
}
